import { useCollection } from '../../hooks/useCollection'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'

// components
import ProductList from '../../components/ProductList'
import ProductFilter from './ProductFilter'

// styles
import './Dashboard.css'

export default function Dashboard() {
  const { user } = useAuthContext()
  const { documents, error } = useCollection('products')
  const [filter, setFilter] = useState('PT')

  const changeFilter = (newFilter) => {
    setFilter(newFilter)
  }
  
  const products = documents ? documents.filter(document => {
    switch(filter) {
      case 'all':
        return true
      case 'mine':
        let assignedToMe = false
        document.assignedUsersList.forEach(u => {
          if(u.id === user.uid) {
            assignedToMe = true
          }
        })
        return assignedToMe
      case 'PT':
      case 'EN':
        console.log(document.language, filter)
        return document.language === filter
      default:
        return true
    }
  }) : null

  return (
    <div>
      <h2 className="page-title">Dashboard > Link Generator</h2>
      {error && <p className="error">{error}</p>}
      {documents && <ProductFilter changeFilter={changeFilter} />}
      {products && <ProductList products={products} />}
    </div>
  )
}
