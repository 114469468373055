import { useParams } from "react-router-dom"
import { useDocument } from '../../hooks/useDocument'

// components
import ProductComments from "./ProductComments"
import ProductSummary from "./ProductSummary"

// styles
import './Product.css'

export default function Product() {
  const { id } = useParams()
  const { document, error } = useDocument('products', id)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }

  return (
    <div className="product-details">
      <ProductSummary product={document} />
      <ProductComments product={document} />
    </div>
  )
}
