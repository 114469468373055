import { useState } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import Select from 'react-select'

const cloakingOptions = [
  { value: 'false', label: 'False' },
  { value: 'true', label: 'True' },
]

export default function ProductComments({ product }) {

  const { updateDocument, response } = useFirestore('products')
  const [paymentMethodName, setPaymentMethodName] = useState('')
  const [paymentMethodURL, setPaymentMethodURL] = useState('')
  const [cloaking, setCloaking] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const paymentMethodToAdd = {
      paymentMethodName,
      paymentMethodURL,
      cloaking: cloaking.value,
      id: Math.random() * 10000
    }
    
    await updateDocument(product.id, {
      paymentMethods: [...product.paymentMethods, paymentMethodToAdd],
    })
    if (!response.error) {
      setPaymentMethodName('')
      setPaymentMethodURL('')
      setCloaking('')
    }
  }

  return (
    <div className="product-comments">
      <h4>Product Payment Methods</h4>

      <ul>
        {product.paymentMethods.length > 0 && product.paymentMethods.map(paymentMethod => (
          <li key={paymentMethod.id}>
            <div className="comment-content">
              <p>Payment Method Name: {paymentMethod.paymentMethodName}</p>
            </div>
            <div className="comment-content">
              <p>Payment Method URL: {paymentMethod.paymentMethodURL}</p>
            </div>
            <div className="comment-content">
              <p>Cloaking Enabled: {paymentMethod.cloaking}</p>
            </div>
          </li>
        ))}
      </ul>

      <form className="add-comment" onSubmit={handleSubmit}>
      <label>
          <span>Payment Method Name:</span>
          <input 
            required
            onChange={(e) => setPaymentMethodName(e.target.value)}
            value={paymentMethodName} 
          ></input>
        </label>
        <label>
          <span>Payment Method URL:</span>
          <input 
            required
            onChange={(e) => setPaymentMethodURL(e.target.value)}
            value={paymentMethodURL} 
          ></input>
        </label>
        <label>
          <span>Cloaking Enabled:</span>
          <Select
            onChange={(option) => setCloaking(option)}
            options={cloakingOptions}
          />
        </label>
        <br />
        <button className="btn">Add Payment Method</button>
      </form>
    </div>
  )
}