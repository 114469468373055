import { useState } from 'react'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory } from 'react-router'
import Select from 'react-select'

// styles
import './Create.css'

const languages = [
  { value: 'PT', label: 'PT' },
  { value: 'EN', label: 'EN' },
]

const cloakingOptions = [
  { value: 'false', label: 'False' },
  { value: 'true', label: 'True' },
]

export default function Create() {
  const history = useHistory()
  const { addDocument, response } = useFirestore('products')

  // form field values
  const [name, setName] = useState('')
  const [details, setDetails] = useState('')
  const [language, setLanguage] = useState('')
  const [currency, setCurrency] = useState('')
  const [price, setPrice] = useState('')
  const [paymentMethodName, setPaymentMethodName] = useState('')
  const [paymentMethodURL, setPaymentMethodURL] = useState('')
  const [cloaking, setCloaking] = useState(null)
  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)

    if (!language) {
      setFormError('Please select a product language.')
      return
    }

    const product = {
      name,
      details,
      language: language.value, 
      currency,
      price,
      paymentMethods: [{ id: Math.random() * 10000, paymentMethodName, paymentMethodURL, cloaking: cloaking.value }]
    }

    await addDocument(product)
    if (!response.error) {
      history.push('/')
    }
  }

  return (
    <div className="create-form">
      <h2 className="page-title">Create a new product</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Product name:</span>
          <input
            required 
            type="text" 
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </label>
        <label>
          <span>Product Details:</span>
          <textarea 
            required
            onChange={(e) => setDetails(e.target.value)}
            value={details} 
          ></textarea>
        </label>
        <label>
          <span>Product Language:</span>
          <Select
            onChange={(option) => setLanguage(option)}
            options={languages}
          />
        </label>
        <label>
          <span>Product Currency:</span>
          <input 
            required
            onChange={(e) => setCurrency(e.target.value)}
            value={currency} 
          ></input>
        </label>
        <label>
          <span>Product Price:</span>
          <input 
            required
            onChange={(e) => setPrice(e.target.value)}
            value={price} 
          ></input>
        </label>
        <label>
          <span>Payment Method Name:</span>
          <input 
            required
            onChange={(e) => setPaymentMethodName(e.target.value)}
            value={paymentMethodName} 
          ></input>
        </label>
        <label>
          <span>Payment Method URL:</span>
          <input 
            required
            onChange={(e) => setPaymentMethodURL(e.target.value)}
            value={paymentMethodURL} 
          ></input>
        </label>
        <label>
          <span>Cloaking Enabled:</span>
          <Select
            onChange={(option) => setCloaking(option)}
            options={cloakingOptions}
          />
        </label>


        <button className="btn">Add Product</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}
