//import { Link } from "react-router-dom";
import { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { useDocument } from "../hooks/useDocument"
import Modal from './Modal'


// styles
import './ProductList.css'

export default function ProductList({ products }) {
  const { user } = useAuthContext()
  const { document, error } = useDocument('users', user.uid)
  
  const [showModal, setShowModal] = useState(false)
  const [inPending, setInPending] = useState(false)

  const [linkGenerated, setLinkGenerated] = useState('')
  const [linkGeneratedMessage, setLinkGeneratedMessage] = useState('')
  

  // handlers
  const handleClose = () => {
    setShowModal(false)
    setLinkGenerated('')
    setLinkGeneratedMessage('')
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(linkGenerated);
    setLinkGeneratedMessage('Link copied to clipboard!')
  }

  // fetchURL function
  const fetchUrl = async (url, consultantCode, cloaking) => {

      setInPending(true)
      
      var link = url + consultantCode
      const currentTimeAsMs = Date.now();
      const adjustedTimeAsMs = currentTimeAsMs + (1000 * 60 * 60);
      const adjustedDateObj = new Date(adjustedTimeAsMs);

      var data = {
        "domain":"checkout.thecloudbootcamp.com",
        "originalURL": link,
        "expiresAt": adjustedDateObj,
        "cloaking": false,
        "allowDuplicates":false }; 
    await fetch('https://api.short.cm/links/public', {
        method: 'post',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': 'pk_FTRsHfZbFLa63WGn'
        },
        body: JSON.stringify(data)
      }).then(function(response) {
            return response.json();
        }) 
        .then(function(data){ 
          console.log(data.shortURL);
          setLinkGenerated(data.shortURL); })
          setShowModal(true)
          setInPending(false)
    }

  return (
    <div>
      {products.length === 0 && <p>No products yet!</p>}
      {products.map(product => (
        
        <div className="product-list-div" to={`/products/${product.id}`} key={product.id}>
          <h4>{product.name}</h4>
          <p>{product.details}</p>
          <p>Price: {product.currency} {product.price}</p>
          <div className="assigned-to">
            <p><strong>Payment Methods:</strong></p>
            <div className='buttondiv'>
            {product.paymentMethods.length > 0 && product.paymentMethods.map(paymentMethod => (
              !inPending && <button className='button' onClick={() => fetchUrl(paymentMethod.paymentMethodURL,document.consultantCode,paymentMethod.cloaking)}>{paymentMethod.paymentMethodName}</button>
              || inPending && <button className='button disabled' disabled onClick={() => fetchUrl(paymentMethod.paymentMethodURL,document.consultantCode,paymentMethod.cloaking)}>{paymentMethod.paymentMethodName}</button>
            ))}
            </div>
          </div> 
       </div>
       
      ))}
      {showModal && (
      <Modal handleClose={handleClose}>
        <h2>Generated URL:</h2>
        <p>{linkGenerated}</p>
        <br />
        <button className='btn' onClick={handleCopy}>Copy To Clipboard</button>
        <br /><br />
        <p>{linkGeneratedMessage}</p> 
        {linkGeneratedMessage && <br />}
        {linkGeneratedMessage &&<button className='btn_close' onClick={handleClose}>Close</button>}
      </Modal>
    )}
    </div>
    
  )
}
