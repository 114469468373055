//import Avatar from "../../components/Avatar"

export default function ProductSummary({ product }) {


  return (
    <div>
      <div className="product-summary">
        <h2 className="page-title">{product.name}</h2>
        <p className="due-date">
          {product.details}
        </p>
        <p className="due-date">
          Price: {product.currency} {product.price}
        </p>
      </div>
    </div>
  )
}