import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDJP7e6dgFr0FN0frNG58p2FV520IWF7pE",
  authDomain: "the-cloud-bootcamp-sp.firebaseapp.com",
  projectId: "the-cloud-bootcamp-sp",
  storageBucket: "the-cloud-bootcamp-sp",
  messagingSenderId: "615284820206",
  appId: "1:615284820206:web:7fdcce7b0a7425fabd4bff",
  measurementId: "G-TZBDN7CJVX"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp, projectStorage }